/**
 * @file: routes/dashboard
 * @author jianghaoran
 */

import loadable from 'react-loadable';
import {Spin} from 'skd';
import {Redirect} from 'react-router-dom';

const Loading = Spin;

const routers = [
    {
        path: '/',
        exact: true,
        // 暂无dashboard 直接跳入监考图片页面
        // component: loadable({
        //     loader: () =>
        //         import(
        //             /* webpackChunkName: 'dashboard' */ /* webpackMode: "lazy" */
        //             '@views/dashboard'
        //         ),
        //     loading: Loading
        // }),
        render: () => <Redirect to="/invigilate/examlist" />
    },{
        path: '/invigilate/examlist',
        exact: true,
        component: loadable({
            loader: () =>
                import(/* webpackChunkName: 'invigilateexamlist' */ /* webpackMode: "lazy" */'@views/invigilate/exam-list'),
            loading: Loading
        })
    }, {
        path: '/invigilate/oypiclist',
        exact: true,
        component: loadable({
            loader: () =>
                import(/* webpackChunkName: 'onlypiclist' */ /* webpackMode: "lazy" */'@views/only-pic/main'),
            loading: Loading
        })
    }, {
        path: '/invigilate/piclist/:examid',
        exact: true,
        component: loadable({
            loader: () =>
                import(/* webpackChunkName: 'invigilatepiclist' */ /* webpackMode: "lazy" */'@views/invigilate/picture-list'),
            loading: Loading
        })
    }, {
        path: '/invigilate/piclist2w/:examid', // 2路图片页面
        exact: true,
        component: loadable({
            loader: () =>
                import(/* webpackChunkName: 'invigilatepiclist' */ /* webpackMode: "lazy" */'@views/invigilate/picture-list-2-way'),
            loading: Loading
        })
    }, {
        path: '/invigilate/videolist/:examid',
        exact: true,
        component: loadable({
            loader: () =>
                import(/* webpackChunkName: 'invigilatevideolist' */ /* webpackMode: "lazy" */'@views/invigilate/video-list'),
            loading: Loading
        })
    }, {
        path: '/invigilate/videolist2w/:tabkey/:examid', // 2路视频页面
        exact: true,
        component: loadable({
            loader: () =>
                import(/* webpackChunkName: 'invigilatevideolist' */ /* webpackMode: "lazy" */'@views/invigilate/video-list-2-way'),
            loading: Loading
        })
    }
];

export default routers;
