/**
 * @file 页面的头部
 * @author jianghaoran01
 */
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Menu, Dropdown, notification, Avatar} from 'skd';
import {UserOutlined} from '@ant-design/icons';
import {user as userSlice} from '@store/user.slice.js';
import {API_SERVER, post} from '@request/service.js';
import {useLocaleReceiver} from '@local/locale-receiver';
import './index.less';

const PREFIX = 'app-header';

const AppHeader = props => {
    const history = useHistory();
    const userStore = useSelector(userSlice);
    const [pageHeaderLocal] = useLocaleReceiver('pageHeader');

    const onStorageCB = e => {
        console.log('storage change::::', e);
    };

    // 登出
    const logout = async () => {
        const path = `${API_SERVER}/monitor-service/api/monitor/logout`;
        // return new EventSourcePolyfill(path);
        const data = await post(path);
        if (data.code === 200) {
            localStorage.removeItem('author-token');
        } else {
            notification.error({message: data?.data?.message, duration: 5});
        }
        history.push('/login');
    };

    const menuClick = ({key}) => {
        if (key == 1) {
            logout();
        }
    };

    const menu = (
        <Menu onClick={menuClick}>
            <Menu.Item key={1}>{pageHeaderLocal?.logout}</Menu.Item>
        </Menu>
    );

    // 监听storage事件变化
    useEffect(() => {
        window.addEventListener('storage', onStorageCB);
        return () => {
            window.removeEventListener('storage', onStorageCB);
        };
    }, []);

    if (!localStorage.getItem('author-token')) {
        history.push('/login');
    }

    return (
        <div className={PREFIX}>
            <div className={`${PREFIX}-content`}>
                <div className={`${PREFIX}-left`}>{pageHeaderLocal?.title}</div>
                <Dropdown overlay={menu}>
                    <div className={`${PREFIX}-right`}>
                        <Avatar icon={<UserOutlined />} />
                    </div>
                </Dropdown>
            </div>
        </div>
    );
};

export default AppHeader;
