const localeValues = {
    pageHeader: {
        title: '南方航空公司监考系统',
        logout: '退出登录'
    },
    exam: {
        title: '考试列表',
        tableColumnTitle: '考试名称',
        tableColumnTime: '考试时间',
        tableColumnStatus: '考试状态',
        tableColumnOutlineNum: '未进入',
        tableColumnCheckNum: '已登记',
        tableColumnDoingNum: '答题中',
        tableColumnFinishNum: '已交卷',
        tableColumnOpe: '操作',
        noList: '暂无考试',
        gotoPicList: '进入监考',
        tableColumnStatus1: '未开始',
        tableColumnStatus2: '进行中',
    },
    login: {
        title: '监考系统',
        username: '账号',
        password: '密码',
        login: '登录'
    },
    invigliatePictureList: {
        user: '考生',
        userName: '考生姓名',
        endOfSupervise: '结束监考',
        checkEnd: '确定要退出吗?',
        endInfo: '考试已结束，请返回监考列表',
        endMessage: '已结束',
        emptyInfo: '现在没有正在答题中的考生',
        cancel: '取消',
        ok: '确定',
        status0: '未进入考试',
        status1: '考试中',
        status4: '考试中',
        status5: '交卷',
        status6: '已登记',
        totalTextPr: '共', // 分页 共 xxx 条
        totalTextNt: '条', // 分页 共 xxx 条
        preexam: '上一场',
        nextexam: '下一场',
        goback: '返回列表',
    },
    invigliateTitle: {
        tableColumnTitle: '监考账号',
        tableColumnName: '姓名',
        tableColumnContact: '联系方式',
        tableColumnRole: '监考角色',
        tableRoleV1: '监考',
        tableRoleV2: '内部巡考',
        tableRoleV3: '外部巡考',
        tableColumnOnline: '状态',
        tableOnlineV0: '离线',
        tableOnlineV1: '在线',
        showDetail: '查看详情',
        userTotal: '考生总数',
        userOnline: '在线人数',
        userfinish: '交卷人数',
        userOther: '未考人数',
        subtitle: '监考人员:',
        subtitle1: '监考人员: 已到', // 监考人员: 已到1人/共9人
        subtitle2: '人/共',
        subtitle3: '人',
        subtitle4: '已到',
        handup: '正在举手',
        audit: '待审核',
        tabTitle1: '监考页面',
        tabTitle2: '审核页面',
        tabTitle3: '考生列表'
    },
    invigliateVideoView: {
        gotoPicUsers: '返回全部考生',
        preUsers: '上一组',
        nextUsers: '下一组',
        endOfSupervise: '结束监考',
        checkEnd: '确定要退出吗',
        goback: '返回列表',
    },
    invigliateVideoList: {
        cancel: '取消',
        ok: '确定',
        end: '考试已结束，请返回监考列表'
    },
    invigliateVideoItem: {
        eventType0: '非本人面部',
        eventType1: '无法识别面部',
        eventType2: '多个面部',
        eventType3: '手机',
        eventType4: '耳机',
        eventType100: '切屏',
        eventType101: '退出登录',
        eventType102: '全屏',
        eventType103: '禁止copy',
        eventType200: '人工取证（其它）',
        eventType201: '佩戴口罩、墨镜等遮挡面部',
        eventType202: '偏离监控范围',
        eventType203: '离开座位',
        eventType204: '佩戴耳机',
        eventType205: '使用计算器',
        eventType206: '翻阅书籍资料',
        eventType207: '使用电子设备',
        eventType208: '出现他人、涉嫌协助作答',
        eventType209: '拨打、接听电话',
        eventType210: '拍摄屏幕',
        eventType211: '出现与考试相关的音频内容',
        option200: '其它',
        option201: '佩戴口罩、墨镜等遮挡面部',
        option202: '偏离监控范围',
        option203: '离开座位',
        option204: '佩戴耳机',
        option205: '使用计算器',
        option206: '翻阅书籍资料',
        option207: '使用电子设备',
        option208: '出现他人、涉嫌协助作答',
        option209: '拨打、接听电话',
        option210: '拍摄屏幕',
        option211: '出现与考试相关的音频内容',
        noImageMessage: '无法获取图片',
        postPhotoMessage: '取证成功',
        status0: '未进入考试',
        status1: '考试中',
        status2: '已交卷',
        status3: '已结束',
        status4: '暂无视频',
        status5: '提交试卷',
        status6: '已登记',
        userId: '考生ID',
        userName: '考生姓名',
        audioStart: '音频连线',
        audioEnd: '结束通话',
        getEvent: '取证',
        kickOut: '踢出',
        kickOutTitle1: '一旦踢出考生，考生将无法继续进行答题，且无法再次登入考试系统',
        kickOutTitle2: '是否踢出?',
        showDetail: '查看详情',
        cancel: '取消',
        ok: '确定',
        submit: '提交',
        placeholder: '请选择',
        help: '请输入取证原因',
        link: '连线中'
    },
    auditList: {
        leftTitle: '正在连接的考生',
        title1: '考生ID',
        title2: '考生姓名',
        failText: '不通过审核',
        audioStart: '音频连线',
        successText: '通过审核',
        modalText: '是否确认考生不通过审核？',
        cancel: '取消',
        link: '连线中',
        cPass: '审核通过',
        cFail: '审核不通过',
        audioEnd: '结束通话'
    }
}

export default localeValues;