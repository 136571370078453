const localeValues = {
    pageHeader: {
        title: '空間概念研究所監視システム',
        logout: 'ログアウトした'
    },
    exam: {
        title: 'テストリスト',
        tableColumnTitle: 'テスト名',
        tableColumnTime: 'テスト時間',
        tableColumnStatus: 'テスト状態',
        tableColumnOutlineNum: '試験に参加していない',
        tableColumnCheckNum: '登録済み',
        tableColumnDoingNum: '解答中',
        tableColumnFinishNum: '提出済み',
        tableColumnOpe: '操作',
        noList: '受験できる試験はいない',
        gotoPicList: '監視システムに入る',
        tableColumnStatus1: '未開始',
        tableColumnStatus2: '進行中',
    },
    login: {
        title: '試験監督システム',
        username: 'アカウント',
        password: 'パスワード',
        login: 'とうろく'
    },
    invigliatePictureList: {
        user: '受験者',
        userName: '考生姓名',
        endOfSupervise: '監視を終了する',
        checkEnd: 'ログアウトしでよろしいですか',
        endInfo: '試験はすでに終了しました。監視リストに戻ってください',
        endMessage: '終了しました',
        emptyInfo: '解答中の受験生はいない',
        cancel: 'キャンセル',
        ok: '確定',
        status0: '試験未参加',
        status4: '試験中',
        status5: '解答を提出する',
        status6: '登録済み',
        totalTextPr: '合計', // 分页 共 xxx 条
        totalTextNt: '項', // 分页 共 xxx 条
        preexam: '前のテスト',
        nextexam: '次のテスト',
        goback: 'リストに戻る',
    },
    invigliateTitle: {
        tableColumnTitle: '監視員アカウント',
        tableColumnName: '姓名',
        tableColumnContact: '連絡先',
        tableColumnRole: '監視員役割',
        tableRoleV1: '監視員',
        tableRoleV2: '内部巡考',
        tableRoleV3: '外部巡考',
        tableColumnOnline: '状態',
        tableOnlineV0: 'オフライン',
        tableOnlineV1: 'オンライン',
        showDetail: '詳細',
        userTotal: '受験者人数',
        userOnline: 'オンライン人数',
        userfinish: '提出済み人数',
        userOther: '未参加人数',
        subtitle1: '監視員:', // 监考人员: 已到1人/共9人
        subtitle2: '合計',
        subtitle3: '人',
        subtitle4: '参加中',
        handup: '挙手中',
        audit: '審査待ち',
        tabTitle1: '监考页面',
        tabTitle2: '审核页面'
    },
    invigliateVideoView: {
        gotoPicUsers: '全員画面',
        preUsers: '前のグループ',
        nextUsers: '次のグループ',
        endOfSupervise: '監視を終了する',
        checkEnd: 'ログアウトしてよろしいですか',
        goback: 'リストに戻る',
    },
    invigliateVideoList: {
        cancel: 'キャンセル',
        ok: '確定',
        end: '試験はすでに終了しました。監視リストに戻ってください'
    },
    invigliateVideoItem: {
        eventType0: '本人以外',
        eventType1: '顔認識ができない',
        eventType2: '複数の顔を認識',
        eventType3: '携帯を使用',
        eventType4: 'イヤホンを使用',
        eventType100: '他の画面への切り替え',
        eventType101: 'ログアウトした',
        eventType102: '最大化の解除',
        eventType103: 'コピー',
        eventType200: 'その他(手動入力)',
        eventType201: 'マスクやサングラスなどで顔が隠れている',
        eventType202: '監視範囲から外れている',
        eventType203: '離席',
        eventType204: 'イヤホンを使用',
        eventType205: '計算機を使用',
        eventType206: '書籍や資料を使用',
        eventType207: '電子機器を使用',
        eventType208: '第二者がいる、協力解答の疑い',
        eventType209: '電話をした',
        eventType210: '画面を撮影',
        eventType211: '試験関連の音声コンテンツが出る',
        option200: 'その他',
        option201: 'マスクやサングラスなどで顔が隠れている',
        option202: '監視範囲から外れている',
        option203: '離席',
        option204: 'イヤホンを使用',
        option205: '計算機を使用',
        option206: '書籍や資料を使用',
        option207: '電子機器を使用',
        option208: '第二者がいる、協力解答の疑い',
        option209: '電話をした',
        option210: '画面を撮影',
        option211: '試験関連の音声が聞こえた',
        noImageMessage: '画像が取れない',
        postPhotoMessage: '証拠を取れました',
        status0: '試験未参加',
        status1: '試験中',
        status2: '提出済み',
        status3: '終了済み',
        status4: '動画がない',
        userId: '受験者ID',
        userName: '受験者氏名',
        audioStart: '音声通話',
        audioEnd: '通話を終了する',
        getEvent: '証拠を取る',
        kickOut: '試験を強制終了する',
        kickOutTitle1: '一度試験を強制終了したら、受験者は問題を解くことができなくなり、また試験システムにアクセスすることができなくなる',
        kickOutTitle2: '排除しますか?',
        showDetail: '詳細',
        cancel: 'キャンセル',
        ok: '確定',
        submit: '提出',
        placeholder: '選択してください',
        help: '証拠を取るの理由を入力してください',
        link: '连线中'
    },
    auditList: {
        leftTitle: '接続中の受験者',
        title1: '受験者ID',
        title2: '受験者氏名',
        failText: '審査不通過',
        audioStart: '音声接続中',
        successText: '審査済み',
        modalText: '審査不通過を確定してよろしいですか',
        cancel: 'キャンセル',
        link: '连线中',
        cPass: '审核通过',
        cFail: '审核不通过',
        audioEnd: '通話を終了する'
    }
}

export default localeValues;