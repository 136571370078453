/**
 * @file 入口文件
 * @author jianghaoran01
 */
import React, {useEffect} from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {store} from './store';
import PrimaryLayout from './layout/primary-layout';
import {ConfigProvider} from 'skd';
import zhCN from 'skd/lib/locale/zh_CN';
// import jaJP from 'skd/lib/locale/ja_JP';
import './App.css';

function App() {

  useEffect(() => {
    // document.title = '試験監督システム';
    document.title = '监考系统';
  }, []);

  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <ConfigProvider locale={zhCN}>
          <PrimaryLayout />
        </ConfigProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
