/**
 * @file 监考store
 * @author jianghaoran01
 */
import {createSlice} from '@reduxjs/toolkit';
// import {author} from '@request/author';
// import request, {post} from '@request/service';

const initialState = {
    detail: {
        title: '',
        invigiltate: {ed: 0, total: 0},
        users: {online: 0, finish: 0, total: 0, handup: 0}
    },
    picturePagesParams: {page: 1, size: 24},
    pictureList: [],
    videoPagesParams: {page: 1, size: 4},
    videoList: [],
};

export const invigilateSlice = createSlice({
    name: 'invigilateSlice',
    initialState,
    reducers: {
        updateDetail: (state, action) => {
            state.detail = action.payload
        },
        updatePicturePagesParams: (state, action) => {
            state.picturePagesParams = action.payload
        },
        updatePictureList: (state, action) => {
            state.pictureList = action.payload
        }
    }
});

export const invigilate = state => state.invigilateSlice;

export const {updateDetail, updatePicturePagesParams, updatePictureList} = invigilateSlice.actions;
